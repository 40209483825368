import React from "react"
import PropTypes from "prop-types"
import styles from "./style.module.css"


export default class extends React.Component {

    static propTypes = {
        color: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        isOpen: PropTypes.bool.isRequired,
        noScroll: PropTypes.bool,
        direction: PropTypes.oneOf(["side", "top"])
    }

    constructor(props) {
        super(props)
        this.bodyToggle = this.bodyToggle.bind(this)
    }

    bodyToggle(toggle) {
        if (this.props.noScroll) {
            const body = document.getElementsByTagName("body")[0]
            if (toggle) {
                body.className = body.className.split(" ").filter(x => !x || x.trim() !== styles.noscroll).concat([styles.noscroll]).join(" ")
            } else {
                body.className = body.className.split(" ").filter(x => !x || x.trim() !== styles.noscroll).join(" ")
            }
        }
    }

    componentWillUnmount() {
        this.bodyToggle(false)
    }

    componentDidUpdate() {
        this.bodyToggle(this.props.isOpen)
    }

    render() {
        return (

            <div
                role="presentation"
                onClick={this.props.onClick}
                // onMouseDown={this.props.onClick}
                style={{ backgroundColor: this.props.color || "transparent" }}
                className={`${styles.mask} ${this.props.direction === "side" ? styles.side : styles.top}  ${this.props.isOpen ? styles.expand : ""}`}
            />
        )
    }
}