import React from "react"
import classnames from "classnames"
import { inject, observer } from "mobx-react"
import styles from "./style.module.css"
import logo from "./logo.svg"
import Loader from "../Loader"

export const Stars = ({ value }) => (
    [1, 2, 3, 4, 5].map(x => (
        <i key={x} className={`icon-star-${x > value ? (value > x - 1 ? "half" : "empty") : "full"}`} />
    ))
)

export const Widget = inject(stores => ({
    reviews_store: stores.reviews_store
}))(
    observer(
        ({ reviews_store, className }) => (
            <div className={classnames(styles.wrapper, "d-flex align-items-center")}>
                {
                    reviews_store.reviews ?
                        (
                            <aside className={classnames(styles.widget, className)}>
                                <a href="https://g.page/the-removal-man/review?gm" target="_blank" rel="nofollow noopener noreferrer">
                                    <div className="d-flex">
                                        <img src={logo} alt="Google Review Logo" width="50px" height="50px" />
                                        <div className="d-flex flex-col">
                                            <span className={styles.header}>Google Rating</span>
                                            <div className="d-flex align-items-center">
                                                <strong>{reviews_store.reviews.average}</strong>
                                                <Stars value={reviews_store.reviews.average} />
                                            </div>
                                        </div>
                                    </div>
                                    <small>recommended by {reviews_store.reviews.count} people</small>
                                </a>
                            </aside>
                        ) : <Loader />
                }

            </div>
        )
    ))
