import { observable, action, reaction } from "mobx"

class TrackerControllerStore {

    @observable fbLoaded = false

    @observable gaLoaded = false

    @observable _fbEventsQueue = []
    @observable _gaEventsQueue = []

    constructor() {
        this._disposers = [].concat(
            reaction(() => this.fbLoaded && this._fbEventsQueue.map(x => x), (canProcess) => {
                if (canProcess && window.fbq) {
                    for (let e; (e = this._fbEventsQueue.pop());) {
                        window.fbq('track', e)
                    }
                }
            }),
            reaction(() => this.gaLoaded && this._gaEventsQueue.map(x => x), (canProcess) => {
                if (canProcess && window.ga) {
                    for (let e; (e = this._gaEventsQueue.pop());) {
                        window.ga('send', 'event', e)
                    }
                }
            })
        )
    }

    dispose() {
        this._disposers.forEach(d => d())
    }

    @action.bound
    initFacebookTracker(fbq) {
        // fbq('init', '472884549866601');
        this.fbLoaded = true
    }

    @action.bound
    initGA(ga) {
        ga('create', process.env.GOOGLE_ANALYTICS, 'auto');
        ga('send', 'pageview');
        ga('require', 'eventTracker');
        ga('require', 'outboundLinkTracker');
        ga('require', 'urlChangeTracker');
        this.gaLoaded = true
    }

    @action.bound
    trackWithFacebook(tracker) {
        this._fbEventsQueue.push(tracker)
    }

    @action.bound
    trackWithGA(tracker) {
        this._gaEventsQueue.push(tracker)
    }

}

const instance = new TrackerControllerStore()
export default instance