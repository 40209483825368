import React, { useState } from "react"
import classnames from "classnames"
import styles from "./style.module.css"
import { inject, observer } from "mobx-react"
import { LongLogo } from "../Logo"
import { copy } from "../../utils/clipboard"

const Tel = () => {
    const [copied, setCopied] = useState(false)
    return (
        <div className={classnames(styles.tel, { [styles.copied]: copied })}>
            <span >{process.env.TEL}</span> <button onClick={() => {
                copy(document, process.env.TEL, () => {
                    setCopied(true)
                    setTimeout(() => setCopied(false), 1500)
                })
            }} title="Copy to clipboard"><i className="icon-content_paste" /></button>
        </div>
    )
}

const PhoneCallIntent = inject(stores => ({
    ui: stores.header_store
}))(
    observer(({ ui }) => {
        return (
            <section className={classnames(styles.wrapper, { [styles.visible]: ui.callIntentVisible }, "d-flex flex-col")}>
                <button className="hover-drop font-weight-bold" onClick={() => ui.setCallIntentVisible(false)} title="close">X</button>
                <div className={"flex-fill d-flex flex-col justify-content-center align-items-center"}>
                    <h1>We are ready to help.</h1>
                    <Tel />
                </div>
                <footer>
                    <LongLogo />
                </footer>
            </section >
        )
    })
)

export default PhoneCallIntent