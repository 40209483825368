import React from "react"
import classnames from "classnames"
import { LongLogo } from "../Logo"
import styles from "./style.module.css"
import { observer, inject } from "mobx-react"
import { Link } from "gatsby"
import Telephone from "../Telephone"
import "hamburgers/dist/hamburgers.min.css"
import Helmet from "react-helmet"
import Side from "../Side"
import ScrollMenu from 'react-horizontal-scrolling-menu';

const Arrow = ({ text, className }) => {
    return (
        <div
            className={className}
        >{text}</div>
    );
};


const ArrowLeft = Arrow({ text: '<', className: styles.arrow });
const ArrowRight = Arrow({ text: '>', className: styles.arrow });

const SideMenu =
    inject(stores => ({
        ui: stores.header_store,
    }))(
        observer(({ ui }) => (
            <Side zIndex={10} direction="top" className={styles.side} noMask isOpen={ui.expanded}>
                <nav>

                    <ScrollMenu
                        data={[
                            { text: "Services", url: "/services" },
                            { text: "Quote", url: "/quote" },
                            { text: "Blog", url: "/blog" },
                            { text: "Reviews", url: "/reviews" },
                            { text: "Contacts", url: "/contacts" },
                        ].map((item, i) => (
                            <Link className={styles.menuItem} key={i} to={item.url}>{item.text}</Link>
                        ))}
                        // hideArrows={true}
                        // hideSingleArrow={true}
                        arrowLeft={ArrowLeft}
                        arrowRight={ArrowRight}
                        selected={ui.menuSelection}
                        onSelect={(key) => ui.selectMenu(key)}
                    />
                </nav>
                    {/* <nav>
                    <ul className="d-flex">
                    <li><Link to="/blog">Blog</Link></li>
                    <li><Link to="/blog">Blog</Link></li>
                    <li><Link to="/blog">Blog</Link></li>
                    <li><Link to="/blog">Blog</Link></li>
                    </ul>
                </nav> */}
            </Side>
        ))
    )

const FullHeader = inject(stores => ({
    ui: stores.header_store,
    tracker: stores.tracker_controller,
    context: stores.context_store
}))(
    observer(({ ui, tracker, context }) => (
        <nav className="d-flex">
            <button className={classnames("hamburger hamburger--collapse", { "is-active": ui.expanded })} type="button"
                onClick={() => ui.setExpanded(!ui.expanded)}
            >
                <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                </span>
            </button>
            <LongLogo />
            <ul className="d-flex">
                <li><Link to="/quote" className={classnames(styles.button, styles.quote, "hover-drop")}>{context.version === "2" ? "Instant Quote" : "Quote"}</Link></li>
                <li>
                    <Telephone className="btn-link text-black"
                        onDesktopClick={() => {
                            ui.setCallIntentVisible(true)
                            tracker.trackWithGA({
                                eventCategory: "Telephone",
                                eventAction: "click",
                                eventLabel: "header - desktop",
                                eventValue: 1
                            })
                        }}
                        onMobileClick={() => {
                            tracker.trackWithGA({
                                eventCategory: "Telephone",
                                eventAction: "click",
                                eventLabel: "header - mobile",
                                eventValue: 1
                            })
                        }}
                    >
                        <i className="icon-call" />
                    </Telephone>
                    {/* <button onClick={() => ui.setCallIntentVisible(true)} className="btn-link hover-drop" title="Book our services"><i className="icon-call" /></button> */}
                </li>
                {/* <li>
                <a href="https://wa.me/message/EFYPDIUHNYN4G1" target="_blank noopener noreferrer" title="Chat on Whatsapp"><i className="icon-whatsapp"/></a>
                </li> */}
            </ul>
        </nav>
    ))
)

const NoQuoteHeader =
    inject(stores => ({
        ui: stores.header_store,
        tracker: stores.tracker_controller
    }))(
        observer(({ ui, tracker }) => (
            <nav className="d-flex justify-content-between">
                <button className={classnames("hamburger hamburger--collapse", { "is-active": ui.expanded })} type="button"
                    onClick={() => ui.setExpanded(!ui.expanded)}
                >
                    <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                    </span>
                </button>
                <LongLogo />
                <ul className="d-flex">
                    <li />
                    <li>
                        <Telephone className="btn-link text-black"
                            onDesktopClick={() => {
                                ui.setCallIntentVisible(true)
                                tracker.trackWithGA({
                                    eventCategory: "Telephone",
                                    eventAction: "click",
                                    eventLabel: "header - desktop",
                                    eventValue: 1
                                })
                            }}
                            onMobileClick={() => {
                                tracker.trackWithGA({
                                    eventCategory: "Telephone",
                                    eventAction: "click",
                                    eventLabel: "header - mobile",
                                    eventValue: 1
                                })
                            }}
                        >
                            <i className="icon-call" />
                        </Telephone>
                    </li>
                    {/* <li>
                        <a target="_blank noopener noreferrer" href="https://wa.me/message/EFYPDIUHNYN4G1" title="Chat on Whatsapp"><i className="icon-whatsapp"/></a>
                    </li> */}
                </ul>
            </nav>
        ))
    )

const HeaderRouter = ({ mode }) => {
    switch (mode) {
        case "no-quote":
            return <NoQuoteHeader />
        default:
            return <FullHeader />
    }
}



@inject(stores => ({
    ui: stores.header_store,
}))
@observer
class Header extends React.Component {

    render() {
        const { headerMode, ui, scroll, scrolled, gray, className } = this.props
        return (
            <div className={classnames(styles.wrapper, className, { [styles.gray]: gray }, { [styles.scrolled]: scrolled }, { [styles.scrollable]: scroll }, { [styles.expanded]: ui.expanded })}>
                <header>
                    <Helmet bodyAttributes={{
                        class: headerMode === "no-quote" ? "" : "with-instaquote"
                    }} />
                    <HeaderRouter mode={headerMode} />
                </header>
                <SideMenu />
            </div>
        )
    }
}

export default Header