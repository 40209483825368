import React from "react"

import "../../assets/css/main.css"
import "../../assets/css/blog.css"
import "../../assets/css/bootstrap-spacing.css"
import "../../assets/icons/style.css"

import Header from "../Header"
import Footer from "../Footer"

import classnames from "classnames"
import styles from "./style.module.css"
import { observable, action } from "mobx"
import { observer, Provider } from "mobx-react"
import { TriggerInstance, systemInitializerFactory } from "../PositionalTrigger"
import HeaderStore from "../../stores/header.store"
import PhoneCallIntent from "../PhoneCallIntent"
import TrackerControllerInstance from "../../stores/tracker_controller.store"
import GoogleAnalytics from "../GoogleAnalytics"
import CookieBanner from "../CookieBanner2"

@observer
class Layout extends React.Component {

    @observable onTop = false

    constructor(props) {
        super(props)
        if (props.scroll) {
            this.trigger = systemInitializerFactory(["header-top"], this.handleTrigger)[0]
        }
        this.stores = {
            header: new HeaderStore(),
            tracker: TrackerControllerInstance
        }
    }

    componentDidMount() {
        this.stores.header.init()
    }

    @action.bound
    handleTrigger(api) {
        this.onTop = api.above("header-top")
    }

    render() {
        const { children, scroll, gray, headerMode = "full", noCookies } = this.props
        return (
            <Provider
                header_store={this.stores.header}
                tracker_controller={this.stores.tracker}
            >
                <div className={classnames(styles.wrapper, { [styles.gray]: gray }, { [styles.scrollable]: scroll }, { [styles.scrolled]: this.onTop })}>
                    {scroll ? (
                        <TriggerInstance className={styles.trigger} instance={this.trigger} />
                    ) : null}
                    {/* <Helmet>
                        <link href="https://fonts.googleapis.com/css?family=Poppins|Open+Sans&display=swap" rel="stylesheet" />
                    </Helmet> */}
                    {noCookies ? null : <CookieBanner />}
                    <Header className={styles.header} gray={gray} scroll={scroll} scrolled={this.onTop} headerMode={headerMode} />
                    <main className={styles.main}>
                        {children}
                    </main>
                    <Footer />


                    <PhoneCallIntent />

                    <GoogleAnalytics />
                </div>
            </Provider>
        )
    }
}

Layout.defaultProps = {
    scroll: true
}

export default Layout
