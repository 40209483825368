import React from "react"
import { LongLogo } from "../Logo"
import { Link } from "gatsby"
import classnames from "classnames"
import styles from "./style.module.css"
import { Widget } from "../GoogleReviews"

const Footer = () => (

    <footer className={classnames("bg-light", styles.wrapper)}>
        <div className="content d-flex justify-content-between align-items-center flex-wrap">
            <div className={classnames(styles.company, "flex-fill")}>
                <LongLogo />
                <h2>The Removal Man</h2>
                {/* <p><span>Address</span> {process.env.ADDRESS.split("/").map((p, i) => (<React.Fragment key={i}><br /> {p}</React.Fragment>))}</p> */}
                <p><span>Tel</span> {process.env.TEL}</p>
                <p><span>Email</span>  {process.env.EMAIL}</p>
                <Widget />
            </div>
            <nav className={styles.nav}>
                <ul>
                    <li><Link to="/services">Our Services</Link></li>
                    <li><Link to="/quote">Online Quote</Link></li>
                    <li><Link to="/blog">Blog</Link></li>
                    <li><Link to="/reviews">Testimonials</Link></li>
                    <li><Link to="/contacts">Contacts</Link></li>
                </ul>
            </nav>
        </div>
        <nav className={classnames(styles.disclaimers, "content")}>
            <ul className="d-flex flex-wrap">
                <li><Link to="/tos"><small>Terms of Service</small></Link></li>
                <li><Link to="/privacy"><small>Privacy</small></Link></li>
            </ul>
        </nav>
        <hr />
        <p className="content">
            <small>© Copyright {new Date().getFullYear()} The Removal Man. All rights reserved. </small>
        </p>
    </footer>
)

export default Footer