import React from "react"
import { observable, computed, reaction, action } from "mobx"

class Handler {

    @observable
    triggers = []

    constructor(cb) {
        this.cb = cb
        this._disposers = [].concat(
            reaction(() => this.initialized, init => {
                if (init) {
                    this.cb.onChange(this.api)
                }
            }),
            reaction(() => this.api, api => {
                if (this.initialized) {
                    this.cb.onChange(api)
                }
            })
        )
    }

    @action.bound
    dispose() {
        this._disposers.forEach(d => d())
    }

    @action.bound
    register(trigger) {
        this.triggers.push({
            name: trigger.name,
            initialized: false,
            observer: null,
            position: {
                visible: false,
                top: 0
            }
        })
    }

    @action.bound
    process(entries, trigger) {
        trigger.position.visible = entries[0].isIntersecting || entries[0].intersectionRatio > 0
        trigger.position.top = entries[0].boundingClientRect.top
    }

    @action.bound
    unregister(trigger) {
        this.triggers.filter(t => t.name === trigger.name).forEach((t) => {
            t.initialized = false
        })
        if (this.triggers.filter(t => t.initialized).length === 0) {
            this.dispose()
        }
    }

    @action.bound
    init(trigger) {
        this.triggers.filter(t => t.name === trigger.name).forEach(t => {
            t.elm = trigger.ref.current
            t.initialized = true
            t.observer = new IntersectionObserver((entries) => this.process(entries, t), {
                root: null, //viewport
                rootMargin: "0px",
                threshold: 1.0
            })
            t.observer.observe(t.elm)
        })
    }

    @computed
    get initialized() {
        return this.triggers.map(t => t.initialized).every(Boolean)
    }

    @computed
    get api() {
        //save cpu cycles for after initialization
        if (!this.initialized) return false
        const data = this.triggers.reduce((res, t) => {
            res[t.name] = {
                name: t.name,
                ...t.position
            }
            return res
        }, {})
        const isVisible = name => data[name].visible
        //target is above the viewport
        const above = name => data[name].top < 0
        //target is below theviewport
        const below = name => data[name].top > window.innerHeight
        const between = (name1, name2) => {
            return above(name1) && below(name2)
        }
        return {
            isVisible,
            above,
            below,
            between
        }
    }


}

class Trigger extends React.Component {

    constructor(props) {
        super(props)
        this.name = props.name
        this.ref = React.createRef()
        this.handler = props.handler
        this.handler.register(this)
    }

    componentDidMount() {
        this.handler.init(this)
    }

    componentWillUnmount() {
        this.handler.unregister(this)
    }

    render() {
        const { className, style } = this.props
        return <div id={`trigger-${this.name}`} style={style} className={className} ref={this.ref} />
    }
}

const TriggerInstance = ({ instance, ...otherProps }) => React.createElement(instance, otherProps)

const systemInitializerFactory = (triggerNames, onTriggerChange = () => false) => {
    const handler = new Handler({
        onChange: onTriggerChange,
    })
    const TriggerFactory = ({ name, className, style }) => (
        <Trigger name={name} handler={handler} className={className} style={style} />
    )
    return triggerNames.map(name => (props) => <TriggerFactory {...props} name={name} />)
}

export {
    TriggerInstance,
    systemInitializerFactory
}