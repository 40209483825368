import React from "react"
import classnames from "classnames"
import styles from "./style.module.css"

const Telephone = ({ onDesktopClick, onMobileClick, children, className }) => (
    <>
        <button onClick={onDesktopClick} className={classnames(className, styles.desktop)} title="Book our services">{children}</button>
        <a onClick={onMobileClick} href={`tel:${process.env.TEL}`} className={classnames(className, styles.phone)} title="Book our services">{children}</a>
    </>
)

export default Telephone