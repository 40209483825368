
import React from "react"
import { Link } from "gatsby"
import { observable, action, computed, runInAction } from "mobx"
import { observer, inject, Observer } from "mobx-react"
import classnames from "classnames"
import Modal from "../Modal"

import styles from "./style.module.css"
import Button from "../Button";
import Switch from "../Switch";
import { LongLogo } from "../Logo";
import Side from "../Side";


const CookieHeader = () => (
  <header className="px-3 text-center mt-3">
    <LongLogo className="w-75" />
    <h2 className="mt-1 mt-sm-3">
      Data and Cookie Consent
    </h2>
  </header>
)

const CookieDisclaimerController = ({ onAccept, onShowPreferences }) => (

  <div className="d-flex">
    <Button mode="link" className="text-primary text-fatty pr-3"
      onClick={onAccept}
    >
      ACCEPT
    </Button>
    <Button mode="link" className="text-secondary text-fatty px-3"
      onClick={onShowPreferences}
      title="Change Cookie Preferences"
    >
      <i className="icon icon-cog h4 position-relative" style={{top: "-2px"}}/>
      {/* PREFERENCES */}
    </Button>
  </div>
)

const CookieDisclaimer = ({ isOpen, onAccept, onShowPreferences }) => (
  <Side className={classnames("w-100", styles.disclaimerWrapper)} isOpen={isOpen} direction="bottom" noMask zIndex={9999}>
    <aside className={classnames(styles.disclaimer, "bg-white w-100 border-top d-flex px-0 px-sm-5")}>
      <h3 className="sr-only">Cookie Disclaimer</h3>
      <div className="flex-fill px-2 text-justify small">
        <p className="font-weight-bold">
          We use cookies to run and improve this website.
        </p>
        {/* <p>
          Click “Accept” to consent to the full use of cookies on this website or “Change Preferences” to control what data is collected.
        </p> */}
        <p className="mt-0">
          <Link to="/privacy">Learn more</Link>
        </p>
      </div>
      {/* <hr /> */}
      <CookieDisclaimerController onAccept={onAccept} onShowPreferences={onShowPreferences} />
    </aside>
  </Side>
)

const CookiePreferencesController = ({ onShowDisclaimer, onSave }) => (
  <div className="row m-4">
    <div className="col p-1">
      <Button mode="paper" block className="bg-secondary text-white"
        onClick={onShowDisclaimer}
      >
        BACK
      </Button>
    </div>
    <div className="col p-1">
      <Button mode="paper" className="bg-primary text-white" block
        onClick={onSave}
      >
        SAVE AND EXIT
      </Button>
    </div>
  </div>
)

const PreferenceSectionButton = ({ name, active, onChange }) => (
  <button
    className={classnames("p-3", styles.preference_button, { [`${styles.active}`]: active })}
    onClick={() => onChange(name)}
  >
    <small>
      {name}
    </small>
  </button>
)

const NecessarySection = () => (
  <section className="text-center mb-3 mt-2">
    <h4 className="sr-only">Necessary Cookies Settings</h4>
    <p>
      These cookies are necessary for the website to function and cannot be switched off in our systems.
      They are usually only set in response to actions made by you which amount to a request for services,
      such as setting your privacy preferences, logging in or filling in forms.
    </p>

    <p>
      You can set your browser to block or alert you about these cookies, but some parts of the site will not then work.
      These cookies do not store any personally identifiable information.
  </p>
  </section>
)

const FunctionalSection = ({ set, active }) => (
  <section className="text-center mb-3 mt-2">
    <h4 className="sr-only">Functional Cookies Settings</h4>
    <Switch
      className="d-block"
      activeColor="primary"
      inactiveColor="secondary"
      onChange={() => set(!active)} active={active}
    >
      {active ? "Enabled" : "Disabled"}
    </Switch>
    <p>
      These cookies enable the website to provide enhanced functionality and personalisation.
      They may be set by us or by third party providers whose services we have added to our pages.
    </p>

    <p>
      If you do not allow these cookies then some or all of these services may not function properly.
  </p>
  </section>
)




const PerformanceSection = ({ set, active }) => (
  <section className="text-center mb-3 mt-2">
    <h4 className="sr-only">Performance Cookies Settings</h4>
    <Switch
      className="d-block"
      activeColor="primary"
      inactiveColor="secondary"
      onChange={() => set(!active)} active={active}
    >
      {active ? "Enabled" : "Disabled"}
    </Switch>
    <p>
      These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site.
      They help us to know which pages are the most and least popular and see how visitors move around the site.
    </p>

    <p>
      All information these cookies collect is aggregated and therefore anonymous.
      If you do not allow these cookies we will not know when you have visited our site,
      and will not be able to monitor its performance.
    </p>
  </section>
)

const TargetingSection = ({ set, active }) => (
  <section className="text-center mb-3 mt-2">
    <h4 className="sr-only">Targeting Cookies Settings</h4>
    <Switch
      className="d-block"
      activeColor="primary"
      inactiveColor="secondary"
      onChange={() => set(!active)} active={active}
    >
      {active ? "Enabled" : "Disabled"}
    </Switch>
    <p>
      These cookies may be set through our site by our advertising partners.
      They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites.
    </p>
    <p>
      They do not store directly personal information, but are based on uniquely identifying your browser and internet device.
      If you do not allow these cookies, you will experience less targeted advertising.
    </p>
  </section>
)


@inject(stores => ({
  cookie_manager: stores.cookie_manager_store
}))
@observer
class CookiePreferences extends React.Component {

  @observable activeSection = "Necessary" // Necessary || Functional || Performance || Targeting

  @action.bound
  changeSection(name) {
    this.activeSection = name
  }

  render() {
    const { onShowDisclaimer, cookie_manager, onSave } = this.props
    return (
      <section className={styles.preferences}>
        <h3 className="text-center">Preferences Management</h3>
        <div>
          <div className="d-flex border-top border-bottom">
            <div className="border-right">
              <Observer>
                {() => (
                  <ul>
                    {[
                      "Necessary",
                      "Functional",
                      "Performance",
                      // "Targeting"
                    ].map(name => (
                      <li key={name} className="px-0">
                        <PreferenceSectionButton name={name} onChange={this.changeSection} active={this.activeSection === name} />
                      </li>
                    ))}
                  </ul>
                )}
              </Observer>
            </div>
            <div className="flex-fill align-items-stretch pl-2">
              <div className="w-100" style={{ height: "35vh", paddingRight: "0.5rem", overflowY: "auto" }}>
                <Observer>
                  {() => {
                    switch (this.activeSection) {
                      case "Necessary":
                        return <NecessarySection />
                      case "Functional":
                        return <FunctionalSection
                          set={(flag) => cookie_manager.setFunctionalPolicy(flag)}
                          active={cookie_manager.functionalEnabled} />
                      case "Performance":
                        return <PerformanceSection set={(flag) => cookie_manager.setPerformancePolicy(flag)}
                          active={cookie_manager.performanceEnabled} />
                      case "Targeting":
                        return <TargetingSection set={(flag) => cookie_manager.setTargetingPolicy(flag)}
                          active={cookie_manager.targetingEnabled} />
                      default:
                        return null
                    }
                  }}
                </Observer>
              </div>
              <Link to="/privacy"><small>More Information</small></Link>
            </div>
          </div>
        </div>
        <CookiePreferencesController onSave={onSave} onShowDisclaimer={onShowDisclaimer} />
      </section>
    )
  }
}


@inject(stores => ({
  cookie_manager: stores.cookie_manager_store
}))
@observer
class CookieBanner extends React.Component {

  @observable _loaded = false


  @action.bound
  onDismiss() {
    this.props.cookie_manager.save()
  }

  @computed
  get isOpen() {
    return this._loaded && !this.props.cookie_manager.acknowledged
  }

  async componentDidMount() {
    runInAction(() => {
      this._loaded = true
    })
  }

  render() {
    if (this.props.cookie_manager.preferencesVisible) {
      return (
        <Modal title="Privacy and Cookies Consent" isOpen={this.isOpen} mobileFullscreen >
          <aside className={styles.banner}>
            <CookieHeader />
            <hr />
            <CookiePreferences
              onSave={this.onDismiss}
              onShowDisclaimer={this.props.cookie_manager.showDisclaimer}
            />
          </aside>
        </Modal>
      )
    } else {
      return (
        <CookieDisclaimer isOpen={this.isOpen}
          onAccept={this.onDismiss}
          onShowPreferences={this.props.cookie_manager.showPreferences}
        />
      )
    }


  }

}

export default CookieBanner;