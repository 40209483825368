import React from "react"
import long from "./long-logo-font.svg"
import { Link } from "gatsby"
import classnames from "classnames"
import styles from "./style.module.css"


export const LongLogo = ({className}) => (
    <Link className={classnames(styles.logo, className)} to="/">
        <img src={long}  alt="The Removal Man" />
    </Link>
)

