import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import classnames from "classnames"
import styles from "./style.module.css"
import Button from "../Button";


class Modal extends React.Component {

    constructor(props) {
        super(props)
        this.bodyToggle = this.bodyToggle.bind(this)
        this.state = {
            loaded: false
        }
    }

    bodyToggle(toggle) {
        const body = document.getElementsByTagName("body")[0]
        if (toggle) {
            body.className = body.className.split(" ").filter(x => !x || x.trim() !== styles.noscroll).concat([styles.noscroll]).join(" ")
        } else {
            body.className = body.className.split(" ").filter(x => !x || x.trim() !== styles.noscroll).join(" ")
        }
    }


    componentDidMount() {
        this.setState({ loaded: true })
        this.elm = document.createElement("div")
    }

    componentWillUnmount() {
        if (this.elm) {
            document.getElementById("modal-root").removeChild(this.elm)
        }
        this.bodyToggle(false)
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevState.loaded && this.state.loaded) {
            document.getElementById("modal-root").appendChild(this.elm)
        }
        if (prevProps.isOpen !== this.props.isOpen) {
            this.bodyToggle(this.props.isOpen)
        }
    }

    render() {
        if (!this.state.loaded) return null
        const { onClick, isOpen, children, closeButton, mobileFullscreen, title="Dialog" } = this.props
        return ReactDOM.createPortal(
            <div role="presentation" onClick={onClick} className={classnames(
                styles.wrapper,
                { [styles.fullscreen]: mobileFullscreen },
                { [styles.open]: isOpen },
                { [styles.clickable]: onClick },
                "d-flex flex-column align-items-center justify-content-center"
            )}>

                <div role="dialog" aria-modal="true" aria-label={title} aria-hidden={!isOpen} onClick={e => {
                    e.stopPropagation()
                    // e.preventDefault()
                }} className={classnames(styles.modal)}>
                    {closeButton ? (
                        <div className="border-bottom bg-white d-flex justify-content-end p-2">
                            <Button  title="Close modal" mode="link" onClick={onClick}><i className="icon icon-close p-2" name="close" /></Button>
                        </div>
                    ) : null}
                    {children}
                </div>

            </div>
            , this.elm
        )
    }
}

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    closeButton: PropTypes.bool,
    mobileFullscreen: PropTypes.bool
}


export default Modal