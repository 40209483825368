import { observable, action } from "mobx";
import cookie from "react-cookies"

class HeaderStore {
    @observable callIntentVisible = false

    @observable cookiesAcknowledged = false

    @observable expanded = false

    @observable menuSelection = null

    @action.bound
    init() {
        this.cookiesAcknowledged = cookie.load("cookie-ack") || false
    }

    @action.bound
    ackCookies() {
        this.cookiesAcknowledged = true
        cookie.save("cookie-ack", true)
    }

    @action.bound
    selectMenu(key) {
        this.menuSelection = key
        this.setExpanded(false)
    }

    @action.bound
    setExpanded(flag) {
        this.expanded = flag
    }

    @action.bound
    setCallIntentVisible(flag) {
        this.callIntentVisible = flag
        if (flag) {
            document.body.className = document.body.className.split(" ").filter(x => x !== "noscroll").concat(["noscroll"]).join(" ")
        } else {
            document.body.className = document.body.className.split(" ").filter(x => x !== "noscroll").join(" ")
        }
    }
}

export default HeaderStore