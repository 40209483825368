export const copy = (document, value, onCopy) => {
    const el = document.createElement('textarea');
    const wrapper = document.getElementsByTagName("body")[0]
    el.className = "seo-hide"
    wrapper.appendChild(el)
    el.value = value
    el.select()
    document.execCommand("copy")
    wrapper.removeChild(el)
    onCopy()
}