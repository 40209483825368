import React from "react"
import classnames from "classnames"
import PropTypes from "prop-types"
import Mask from "../Mask"
import styles from "./style.module.css"

const SideBody = ({ isOpen, className, style, children, direction, zIndex = 3 }) => (
  <div
    style={{ ...style, zIndex: zIndex }}
    className={classnames(
      styles.side,
      className,
      { [styles.expand]: isOpen },
      ({
        "left": styles.left,
        "top": styles.top,
        "right": styles.right,
        "bottom": styles.bottom
      })[direction] || styles.top
    )}>
    {children}
  </div>
)

const Side = ({ isOpen, toggle, noScroll, noMask, color = "black", ...otherProps }) =>

  noMask ? (
    <SideBody isOpen={isOpen} {...otherProps} />
  ) : (
      <>
        <SideBody isOpen={isOpen} {...otherProps} />
        <Mask color={color} onClick={toggle} isOpen={isOpen} noScroll={noScroll} />
      </>
    )


Side.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  direction: PropTypes.oneOf(["left", "top", "right", "bottom"]),
  noScroll: PropTypes.bool
}


export default Side