import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import styles from "./style.module.css"

const getColor = (active, disabled, activeColor, inactiveColor) => {
    if (active) {
        return activeColor
    } else {
        return inactiveColor
    }
}

const Switch = ({
    activeColor,
    inactiveColor,
    active,
    disabled,
    onChange,
    children,
    className
}) => (
        <button
            className={classnames("d-flex", className, styles.wrapper)}
            disabled={disabled}
            onClick={onChange}
            aria-label="Switch"
        >
            {React.Children.count(children) > 0 ? (
                <span className={styles.childrenWrapper}>
                    {children}
                </span>
            ) : (
                    null
                )}
            <div className={styles.switch}>
                <span
                    className={`bg-${getColor(active, disabled, activeColor, inactiveColor)}`}
                />
                <span
                    className={classnames(
                        `bg-${getColor(active, disabled, activeColor, inactiveColor)}`,
                        { [styles.on]: active },
                        { [styles.off]: !active }
                    )}
                />
            </div>
        </button>
    )

Switch.defaultProps = {
    activeColor: "primary",
    inactiveColor: "secondary"
}

Switch.propTypes = {
    activeColor: PropTypes.oneOf(["primary", "secondary", "white", "dark", "light", "info", "warning", "danger", "success"]).isRequired,
    inactiveColor: PropTypes.oneOf(["primary", "secondary", "white", "dark", "light", "info", "warning", "danger", "success"]).isRequired,
    active: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
}

export default Switch