import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import style from "./style.module.css"

const Button = ({ onClick, className, disabled, mode, block, children, ...otherProps }) => {
    return (
        <button
            {...otherProps}
            disabled={disabled}
            className={classnames(
                "btn",
                { "btn-block": block },
                { "disabled": disabled },
                { [`${style.air}`]: mode === "air" },
                { [`${style.paper}`]: mode === "paper" },
                { [`${style.border}`]: mode === "border" },
                { "rounded": mode === "round" },
                { "btn-link": mode === "link" },
                className
            )}
            onClick={onClick}
        >
            {children}
        </button>
    )
}

Button.defaultProps = {
    mode: "paper",
    block: false,
    disabled: false,

}

Button.propTypes = {
    className: PropTypes.string,
    mode: PropTypes.oneOf(["paper", "link", "air", "border", "normal", "round"]),
    onClick: PropTypes.func,
    block: PropTypes.bool,
    disabled: PropTypes.bool,
}

const Buttonifier = ({ children, block, disabled, mode, className, ...otherProps }) => (
    React.Children.map(children, child => {
        return (
            React.cloneElement(child, {
                className: classnames(
                    "btn",
                    { "btn-block": block },
                    { "disabled": disabled },
                    { [`${style.air}`]: mode === "air" },
                    { [`${style.paper}`]: mode === "paper" },
                    { [`${style.border}`]: mode === "border" },
                    { "rounded": mode === "round" },
                    { "btn-link": mode === "link" },
                    className
                ),
                ...otherProps
            })
        )
    })
)

Buttonifier.propTypes = {
    className: PropTypes.string,
    mode: PropTypes.oneOf(["paper", "link", "air", "border", "normal", "round"]),
    block: PropTypes.bool,
    disabled: PropTypes.bool,
}

export default Button

export {
    Buttonifier
}

