import React from "react"
import { action, reaction, computed } from "mobx"
import { inject, observer } from "mobx-react"


@inject(context => ({
    tracker_controller: context.tracker_controller,
    cookie_manager: context.cookie_manager_store
}))
@observer
class GoogleAnalytics extends React.Component {


    constructor(props) {
        super(props)

        this._disposers = []
        this._disposers.push(
            reaction(() => this.canLoad, (enabled) => {
                if (enabled) {
                    this.load()
                }
            })
        )
    }

    componentWillUnmount() {
        this._disposers.forEach(dispose => dispose())
    }

    componentDidMount() {
        if (this.canLoad) {
            this.load()
        }
    }

    @computed
    get canLoad() {
        const { cookie_manager } = this.props
        const hasPermission = cookie_manager.acknowledged && cookie_manager.performanceEnabled
        return hasPermission
    }

    @action.bound
    load() {
        if (this.props.tracker_controller.gaLoaded) return;
        // require("./autotrack")
        console.log("loading GA")
        //note: we are initializing GA on every page, so no need to 
        //to use auto-track
        import("./autotrack").then(() => {
            const script = document.createElement("script")
            script.src = `https://www.google-analytics.com/analytics.js`

            script.async = 1
            script.onload = () => {
                // eslint-disable-next-line
                window.ga = window.ga || function () { (ga.q = ga.q || []).push(arguments) }; ga.l = +new Date;
                this.props.tracker_controller.initGA(window.ga)
            }
            script.onerror = () => {
                console.error("failed loading google analytics")
            }

            document.getElementsByTagName("head")[0].appendChild(script)
        })
    }

    render() {
        return (
            null
        )
    }
}


export default GoogleAnalytics